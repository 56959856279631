import { Container } from "react-bootstrap";
import { adClient } from '../common/adConfig'
import { useEffect, useRef, useState } from "react";

const AboutUs = () => {
    useEffect(() => {
        adClient.showBannerAd({
            adUnitId: "271833e4-a718-4d71-b73e-955e35b1b33d",
            containerId: "ads-banner-top"
        }, (errorMessage) => {
            console.log("errorMessage", errorMessage);
            // You can handle error here.
        })
    }, []);


    useEffect(() => {
        adClient.showBannerAd({
            adUnitId: "7ef30ab6-59e1-45b9-9072-591153082a1e",
            containerId: "ads-home-right"
        }, (errorMessage) => {
            console.log("errorMessage", errorMessage);
            // You can handle error here.
        })
    }, []);

    useEffect(() => {
    adClient.showBannerAd({
        adUnitId: "bcfbb4d5-0c6c-49e4-804f-2eb1a21e61f0",
        containerId: "ads-home-left"
    }, (errorMessage) => {
        console.log("errorMessage", errorMessage);
        // You can handle error here.
    })
}, []);

    return (
        <div className="about-outer-section">
            <div class="ads-banner-top" id="ads-banner-top">

            </div>
            <div id="ads-home-right">

            </div>
            <div id="ads-home-left">

            </div>
            <section className="common-padding about-us-section">
                <Container>
                    <div className="about-area-content">
                        <h2>About Us</h2>
                        <p><a target="black" href="https://betfolio.co/">BetFolio</a> is a gamified crypto prediction market, offering a simple and engaging way to bet on various crypto-themed and topical predictions.  </p>
                        <p>We feature predictions ranging from binary outcomes to multi-option polls, focusing on topics relevant to crypto natives, as well as sports and pop culture fans.
                        </p>
                        <h3>Prize Pool Predictions</h3>
                        <p>BetFolio is the first prediction market where you do not need to wager real money. Instead of traditional bets, you cast votes on-chain to predict future outcomes.</p>
                        <p>We offer a unique format called ‘Prize Pool Predictions’. Users pay an entry fee by minting our NFT passes to participate. NFT holders can place bets for free on prize pool predictions.</p>
                        <p>If your prediction is correct, you win a share of the token prize pool provided by BetFolio or our sponsoring partners.</p>
                        <h3>Prediction Types</h3>
                        <h5>We offer the following types of predictions:</h5>
                        <div className="my-3">
                            <h6 className="my-2"><b>Solo Prize Pool Predictions:</b>
                            </h6>
                            <p>Mint our Solo Prediction Pass NFT to place $0 bets on prediction polls and potentially win $5 to $1,000 from the prize pool. Only one person can bet on a solo prediction and win the prize. </p>
                            <ul>
                                <li>Our <a target="blank" href="https://betfolio-solo-prediction-pass.nfts2.me/">Solo Prediction NFT Pass</a> gives you 72 hours to bet on maximum 3 solo predictions</li>
                                <li>Our <a target="blank" href="https://betfolio-extended-solo-pass.nfts2.me/">Extended Solo Prediction Pass </a> gives you 168 hours (7 days) to bet on 10 solo predictions.</li>
                            </ul>
                        </div>
                        <div className="my-3">
                            <h6 className="my-2"><b>Group Prize Pool Predictions:
                            </b>
                            </h6>
                            <p>Multiple people can place bets. Winners evenly split the token prize pool.
                                Users must mint a <a target="blank" href="https://betfolio-risk-free-predictions.nfts2.me/">Group Predictions NFT Pass</a>, which gives you 30 days to bet on these predictions.
                            </p>
                        </div>
                        <div className="my-3">
                            <h6 className="my-2"><b>Multi-Choice Prize Pool Predictions:
                            </b>
                            </h6>
                            <p>Mint our <a href="https://betfolio-multi-choice-pass.nfts2.me/" target="blank">Multi-Choice Prediction Pass NFT</a> to bet on maximum 20 multichoice predictions.  Select the correct 3 or 4 options in a multichoice prediction poll to win up to $150 in hourly prizes and up to $3,000 in daily prizes. More details <a href="https://x.com/BetFolioX/status/1816441950062448854" target="blank">here.</a></p>
                            <p>All prize pool predictions are split into <a href="https://x.com/betfoliox/status/1810327374812061921?s=46" target="blank">5 types</a> based on the amount of tokens in their pools:
                                Pearls, Sapphires, Rubies, Emeralds and Diamonds.
                            </p>
                        </div>

                        <h5>Examples:  </h5>
                        <ul>
                            <li>Prediction Polls:<a target="blank" href="https://betfolio.co/coinmarketcap/0x72ac293867d2b447041f05d0d0e77da015d9cba8"> “Which L2 token will have the highest 24hr return?: ARB, MANTA, MATIC, MNT, or OP”</a></li>
                            <li>Pop Culture predictions:<a target="blank" href="https://betfolio.co/lunarcrush/0xd1f2a0b022afb1a580173b7f7daf0c0b5a993de9">“Which celebrity will have the highest topic rank on LunarCrush by June 6th?: Taylor Swift, Ronaldo, Elon Musk, Drake, or BTS”</a></li>
                            <li>MultiChoice Predictions:<a target="blank" href="https://betfolio.co/multi-choice-predictions/0xaf9dde96b2d0aea8d47b54041a33dc05e8e6eaf2"> Which 3 coins will have the highest 1hr return</a></li>

                        </ul>
                        <p>Prediction timelines are 1 hour, 1 day, 1 week or 1 month.
                        </p>
                        <p>Each day around 8 PM EST (12 AM UTC), our Chainlink oracle contracts pull data from sources like Coinmarketcap, Defillama, LunarCrush, Dappradar and Twitter to execute prediction results. </p>
                        <h3>FOLIO Tokenized Points</h3>
                        <p>FOLIO are tokenized points redeemable for BetFolio NFTs which allow you to bet on <a href="https://betfolio.co/solo-predictions" target="blank">Solo</a> and <a href="https://betfolio.co/multi-choice-predictions" target="blank">Multichoice</a> predictions for free. </p>
                        <p>You can earn FOLIO by accumulating <a href="https://betfolio.co/leaderboard" target="blank">points</a> when you place or win a bet on any prediction. Once you have a minimum 100 points, you can convert it to FOLIO, and then redeem your FOLIO for an NFT on our <a href="https://betfolio.co/redeem" target="blank">redemption page.</a> </p>

                        <h3>How To Bet on a Prediction</h3>
                        <p>The following videos demonstrate how to place a bets on different predictions:
                        </p>

                        <div className="mb-3">
                            <p className="pb-0">Group Prize pool prediction:</p>
                            <a href="https://youtu.be/oH4zCkA6Gt8?si=SLVI7-MQQMBJJQg0" target="blank">https://youtu.be/oH4zCkA6Gt8?si=SLVI7-MQQMBJJQg0 </a>
                        </div>

                        <div className="mb-3">
                            <p className="pb-0">Mulit-Choice Prize pool prediction:</p>
                            <a href="https://youtube.com/shorts/5SIdITcuMLg?si=oePUiWfjCiDSnLsa" target="blank">https://youtube.com/shorts/5SIdITcuMLg?si=oePUiWfjCiDSnLsa  </a>
                        </div>

                        <h3>How Payouts Work for Predictions</h3>
                        <p>The expected payout for predictions is simply the total amount of funds in the prize pool divided by the number of winners.
                        </p>
                        <img src={require("../assets/images/about-image1.png")} />
                        <h3>Predictions Page Layout</h3>
                        <p>All predictions have a start date and time and an end date and time. You can view this on the prediction page.</p>
                        <img src={require("../assets/images/about-image2.png")} />
                        <p>The ‘Betting Window’ is the period of time in which users are allowed to place a bet. This is typically from the moment the prediction is launched until a few days or hours before the end date and time. </p>
                        <p>Once the betting window closes, you will no longer be able to place a bet and must wait until the result is shown. Prediction results are published and funds distributed to users 1 hour after the prediction end date and time. For hourly predictions, results are published 10 minutes after the prediction end time.</p>
                        <img src={require("../assets/images/about-image3.png")} />
                        <p>Under ‘Prediction leader-board’ you can see the list of wallets that made bets and the option(s) they bet on. </p>
                        <img src={require("../assets/images/about-image4.png")} />
                        <p>You can also see your expected payout for a particular betting option based on the amount of tokens in the prize pool divided by the number of people who bet on that option:</p>
                        <img src={require("../assets/images/about-image5.png")} />
                        <p>For any questions, please fill out our contact <a href="https://betfolio.co/contact-form" target="blank">form</a>, reach out to <a href="info@betfolio.co">info@betfolio.co</a> or contact us on <a href="https://t.me/betfoliox">Telegram.</a></p>
                    </div>
                </Container>
            </section>

        </div>
    );
};
export default AboutUs