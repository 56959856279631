import { Dropdown, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Web3 from "web3";
import { gasFeePercent, mintNftsList, NftBuyContractAddress, tokenAddress } from "../config/config";
import { tokenAbi } from "../config/tokenAbi";
import { ethers } from "ethers";
import swal from "sweetalert";
import NftBuyAbi from "../common/NftBuyAbi.json";
import { useState } from "react";

const NftMint = ({ prediction, }) => {
    const web3AuthProvider = useSelector((state) => state.reducer.web3AuthProvider);
    const walletAddress = useSelector((state) => state.reducer.walletAddress);
    const [showFollowSteps, setShowFollowSteps] = useState(false);
    const [tokenApproved, setTokenApproved] = useState(false);

    async function approveToken(mintDate) {
        let returnStatus = false;
        try {
            const web3 = new Web3(web3AuthProvider);
            let tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
            let eRC20TokenBalance = await tokenContract.methods.balanceOf(walletAddress).call();
            const decimals = await tokenContract.methods.decimals().call();
            eRC20TokenBalance = Number(eRC20TokenBalance) / 10 ** Number(decimals);
            const tokenAmount = Number(10000) * 10 ** Number(decimals);
            if (eRC20TokenBalance <= mintDate.tokenValue) {
                swal("Error", "insufficient USDC balance.", "error");
            } else {
                let allowanceInWei = await tokenContract.methods.allowance(walletAddress, NftBuyContractAddress).call();
                allowanceInWei = Number(allowanceInWei) / 10 ** Number(decimals)
                if (allowanceInWei <= mintDate.tokenValue) {
                    if (localStorage.getItem("user_type") == "CUSTODIAL") {
                        const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
                        const signer = ethersProvider.getSigner();
                        tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
                        const balance = await ethersProvider.getBalance(walletAddress);
                        let balacneInETH = ethers.utils.formatEther(balance);
                        if (Number(balacneInETH) < 0.1) {
                            swal({
                                text: "Insufficient funds.",
                                icon: "info",
                            })
                        } else {
                            const tx = await tokenContract.approve(NftBuyContractAddress, tokenAmount.toString());
                            swal({
                                text: "Are you sure want to complete this transactions?",
                                icon: "info",
                                buttons: ["Cancel", "Pay Now"]
                            }).then(async (res) => {
                                if (res) {
                                    const receipt = await tx.wait();
                                    if (receipt) {
                                        console.log("==========Transaction successful, receipt:", receipt);
                                        returnStatus = true;
                                    }
                                }
                            })
                        }
                    } else {
                        // const receipt = await tokenContract.methods.approve(NftBuyContractAddress, tokenAmount.toString()).send({ from: walletAddress });

                        let gas, gasPrice;
                        try {
                            gasPrice = await web3.eth.getGasPrice();
                            gasPrice = Math.ceil(Number(gasPrice) * gasFeePercent);
                            gas = await tokenContract.methods.approve(NftBuyContractAddress, tokenAmount.toString()).estimateGas({ from: walletAddress });
                            gas = Math.ceil(Number(gas) * gasFeePercent);
                        } catch (error) {

                        }

                        const receipt = await tokenContract.methods.approve(NftBuyContractAddress, tokenAmount.toString()).send({
                            from: walletAddress,
                            gas: gas,  // Dynamically estimated gas
                            gasPrice: gasPrice  // Current gas price
                        });
                        console.log("==========Transaction successful, receipt:", receipt);
                        returnStatus = true;
                        allowanceInWei = await tokenContract.methods.allowance(walletAddress, NftBuyContractAddress).call();
                        allowanceInWei = Number(allowanceInWei) / 10 ** Number(decimals)
                        if (allowanceInWei <= mintDate.tokenValue) {
                            return approveToken(mintDate);
                        }
                    }
                } else {
                    returnStatus = true;
                }

            }
        } catch (error) {
            console.log("error", error);
            setShowFollowSteps(false);
        }
        if (!returnStatus) {
            setShowFollowSteps(false);
        }
        return returnStatus;
    }

    async function mintNft(mintData) {
        try {
            setShowFollowSteps(true);
            const isApproved = await approveToken(mintData);
            console.log("isApproved", isApproved);

            if (isApproved) {
                setTokenApproved(true);
                const web3 = new Web3(web3AuthProvider);
                let mintContract = new web3.eth.Contract(NftBuyAbi, NftBuyContractAddress);
                if (localStorage.getItem("user_type") == "CUSTODIAL") {
                    const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
                    const signer = ethersProvider.getSigner();
                    mintContract = new ethers.Contract(NftBuyContractAddress, NftBuyAbi, signer);
                    const balance = await ethersProvider.getBalance(walletAddress);
                    let balacneInETH = ethers.utils.formatEther(balance);
                    if (Number(balacneInETH) < 0.1) {
                        swal({
                            text: "Insufficient funds.",
                            icon: "info",
                        })
                    } else {
                        const tx = await mintContract.mintNFT(mintData.mintId, {
                            gasLimit: 500000, // Fallback if gas estimation fails
                        });
                        swal({
                            text: "Are you sure want to complete this transactions?",
                            icon: "info",
                            buttons: ["Cancel", "Pay Now"]
                        }).then(async (res) => {
                            if (res) {
                                const receipt = await tx.wait();
                                if (receipt) {
                                    console.log("==========Transaction successful, receipt:", receipt);
                                    swal("Success", "NFT mint successfully.", "success").then(res=>{
                                        window.location.reload();
                                    });
                                    setShowFollowSteps(false);
                                    setTokenApproved(false);
                                }
                            }
                        })
                    }
                } else {
                    let gas, gasPrice;
                    try {
                        gasPrice = await web3.eth.getGasPrice();
                        gasPrice = Math.ceil(Number(gasPrice) * gasFeePercent);
                        gas = await mintContract.methods.mintNFT(mintData.mintId).estimateGas({ from: walletAddress });
                        gas = Math.ceil(Number(gas) * gasFeePercent);
                    } catch (error) {

                    }

                    const receipt = await mintContract.methods.mintNFT(mintData.mintId).send({
                        from: walletAddress,
                        gas: gas,  // Dynamically estimated gas
                        gasPrice: gasPrice  // Current network gas price
                    });
                    console.log("==========Transaction successful, receipt:", receipt);
                    swal("Success", "NFT mint successfully.", "success").then(res=>{
                        window.location.reload();
                    });;
                    setShowFollowSteps(false);
                    setTokenApproved(false);
                }
            }

        } catch (error) {
            console.log("error", error);
            setShowFollowSteps(false);
            setTokenApproved(false);
        }
    }

    return (
        <>
            {!walletAddress?.accounts && walletAddress ? <div className="mint-btn">
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Mint NFT
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {mintNftsList.map(item => {
                            return (<Dropdown.Item onClick={() => mintNft(item)}>{item.mintName}</Dropdown.Item>)
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div> : ""}

            <Modal
                show={showFollowSteps}
                backdrop="static"
                onHide={() => setShowFollowSteps(false)}
                centered
                className="connect-wallet-box follow-steps-popup"
            >
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="mt-0">Follow Steps </h3>
                    <div className="follow-steps-ad-mid">
                        <span id="ct_cXLTfT9UNz7"></span>
                        <span id="ct_cv9ftlceqwI"></span>

                    </div>
                    <div className="step-area">
                        <div className="steps-left-area">
                            {tokenApproved ? <h2>✓</h2> : <div class="loader"></div>}
                        </div>
                        <div className="steps-content">
                            <h6>Approving USDC</h6>
                            <p>Approving USDC</p>
                        </div>
                    </div>
                    <div className="step-area">
                        <div className="steps-left-area">
                            {tokenApproved ? <div class="loader"></div> : <h2>2</h2>}
                        </div>
                        <div className="steps-content">
                            <h6>Mint NFT</h6>
                            <p>Send transaction to Mint NFT</p>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};

export default NftMint;
