
import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  formatBalanceWithDecimals
} from "../common/connectWallet";
import { appUrl } from '../config/config';

const isInProgressOld = (startDateTime, endDateTime, predictionTime) => {
  let currentTimeStamp = Math.floor(new Date().getTime() / 1000);
  let startTimeStamp = Math.floor(new Date(startDateTime).getTime() / 1000);
  let endTime = new Date(endDateTime);
  let endTimeMinusPredict = endTime.setMinutes(
    endTime.getMinutes() - predictionTime
  );
  let endTimeMinusPredictStamp = Math.floor(
    new Date(endTimeMinusPredict).getTime() / 1000
  );
  if (currentTimeStamp < endTimeMinusPredictStamp) {
    return true;
  } else {
    return false;
  }
};

const isInProgress = ( endDateTime, predictionTime , extendedTime) => {
  console.log("isInProgress extendedTime",extendedTime);
  const currentTimeStamp = Math.floor(Date.now() / 1000);
  const endTime = new Date(endDateTime);
  console.log("extendedTime >>>>",predictionTime, extendedTime , predictionTime + extendedTime)
  endTime.setMinutes(endTime.getMinutes() - (predictionTime - extendedTime || 0) );
  const endTimeMinusPredictStamp = Math.floor(endTime.getTime() / 1000);

  return currentTimeStamp < endTimeMinusPredictStamp;
};


const isInProgressOrSoloBet = ( endDateTime, predictionTime , extendedTime , data) => {
  console.log("isInProgressOrSoloBet",data.data_provider ,  data.total_prediction , data.data_provider == "solo-predictions" , data.total_prediction > 0 )
  if (data.data_provider == "solo-predictions" && data.total_prediction > 0 ){
    return false;
  }else{
    return isInProgress(endDateTime, predictionTime , extendedTime)
  }
}


const PredictionBoxBottom = ({ data }) => {
  console.log("PredictionBoxBottom>>>>>>>>",data);
  let history = useNavigate();
  const handleButtonClick = (dataProvider, address, event) => {
    if (event.ctrlKey || event.metaKey) {
      window.open(appUrl + "/" + dataProvider + "/" + address, '_blank');
    } else {
      history("/" + dataProvider + "/" + address);
    }

  };

  const getButtonLabel = () => {
    return data.prediction_category === "QUIZ" ? "Answer" : isInProgressOrSoloBet( data.endDateTime, data.threshold_time , data.extendedTime , data) ? "Predict" : "View";
  };
  const tooltip = (
    <Tooltip id="tooltip">
      The betting window for this prediction has closed and it is now on Extra Time. Buy more time on this predictions page to bet now and increase your chance of winning
    </Tooltip>
  );
  return (
    <div className="prdiction-box-bottom">
      <p>
        Total:{" "}
        {data?.prediction_type === "RISK-FREE" ? (
          <span>{data.countsAndAmounts.totalCount} {data.countsAndAmounts.totalCount == 1 ? "bet" : "bets"}</span>
        ) : (
          <span>
            {formatBalanceWithDecimals(data.countsAndAmounts.totalAmount, data?.decimal)} {data?.symbol} ({data.countsAndAmounts.totalCount} {data.countsAndAmounts.totalCount == 1 ? "bet" : "bets"})
          </span>
        )}
      </p>
      
      <div className='predict-extra-time-right'>
        {data.data_provider == "solo-predictions" || data.data_provider == "multi-choice-predictions"  &&
          <OverlayTrigger placement="top" overlay={tooltip}>
            <Button bsStyle="default" className='extra-time-btn'>Extra Time</Button>
          </OverlayTrigger>
        }
        <Button type="button" variant="unset" onClick={(event) => handleButtonClick(data?.data_provider, data?.contract_address, event)}>
          {getButtonLabel()}
        </Button>
      </div>
    </div>
  );
};

export default PredictionBoxBottom;
