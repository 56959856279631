import axios from "axios";
import { apiUrl } from "../config/config";


export const apiService = {
    login,
    loginWithWeb3,
    get_prediction,
    getPredictionFilter,
    get_predictionDetail,
    leaderboardDetail,
    checkprediction,
    userTransaction,
    contactUs,
    subscribePrediction,
    userDetailData,
    predictionCheckforWallet,
    leaderBoardPoints,
    leaderBoardPoints30Days,
    checkBet,
    fetch_solo_prediction,
    fetch_multi_prediction,
    referralLeaderBoard,
    convertRedeem,
    getRedmeedPoints,
    getRedmeedList


 
}


const headers =() =>
    {
        let token = localStorage.getItem("token")
        return {headers : { 'Authorization': `${token}` }} 
    };

//    console.log('headers()....', headers());





async function login(data) {
    return await axios.post(`${apiUrl}/user/login/`, data);
}

async function loginWithWeb3(data) {
  return await axios.post(`${apiUrl}/user/loginweb3/`, data);
}

async function fetch_multi_prediction(limit,status,theme, valueType){
  return await axios.get(`${apiUrl}/user/getMultiChoicePredictions?limit=`+limit+`&type=`+status+`&theme=`+theme+`&solo_type=`+valueType , headers())
}

async function fetch_solo_prediction(limit,status,theme, valueType){
  return await axios.get(`${apiUrl}/user/getSoloPredictions?limit=`+limit+`&type=`+status+`&theme=`+theme+`&solo_type=`+valueType , headers())
}
async function get_prediction(limit,duration,type,prediction,provider_type,searchTerm){
  return await axios.get(`${apiUrl}/user/predictionList?limit=`+limit+"&duration="+duration+"&type="+type+"&prediction_type="+prediction+"&provider_type="+provider_type+"&q="+searchTerm, headers())
}
async function get_predictionDetail(address){
  return await axios.get(`${apiUrl}/user/predictionDetail/`+address, headers())
}
async function leaderboardDetail(address,limit){
  return await axios.get(`${apiUrl}/user/leaderboardDetail/`+address+'?limit='+limit, headers())
}

async function getRedmeedPoints(){
  return await axios.get(`${apiUrl}/user/user-points`, headers())
}

async function getRedmeedList(status,page){
  return await axios.get(`${apiUrl}/user/redeem-points-history?page=${page}&history=${status}`, headers())
}

async function getPredictionFilter(search){
  return await axios.get(`${apiUrl}/user/searchPredictions/${search}`, headers())
}
async function checkprediction(address,wallet_address){
  return await axios.get(`${apiUrl}/user/user-prediction/`+address+"/"+wallet_address)
}


async function userTransaction(page){
  return await axios.get(`${apiUrl}/user/user-transaction?page=`+page,headers())
}

async function userDetailData(){
  return await axios.get(`${apiUrl}/user/userDetail`,headers())
}

async function subscribePrediction(data){
  return await axios.post(`${apiUrl}/user/subscribePrediction`,data,headers())
}

async function contactUs(data){
  return await axios.post(`${apiUrl}/user/contact-us`,data,headers())
}
async function convertRedeem(data){
  return await axios.post(`${apiUrl}/user/redeem-points`,data,headers())
}
async function predictionCheckforWallet(contractAddress,walletAddr){
  return await axios.get(`${apiUrl}/user/prediction-detail/${contractAddress}/${walletAddr}`,headers())
}

async function leaderBoardPoints(page){
  return await axios.get(`${apiUrl}/user/leaderBoard-points?page=`+page, headers())
}

async function leaderBoardPoints30Days(page){
  return await axios.get(`${apiUrl}/user/pointsLeaderBoardLast30Days?limit=50&page=`+page, headers())
}

async function referralLeaderBoard(leaderBoradType,page){
  return await axios.get(`${apiUrl}/user/referelLeaderBoard?type=`+leaderBoradType+"&page="+page, headers())
}

async function checkBet(data){
  return await axios.post(`${apiUrl}/user/placeBet`,data,headers())
}




  
 



















