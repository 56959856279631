import { Col, Dropdown, Modal, NavItem, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Web3 from "web3";
import { betCounterAddress, mintNftsList, multiChoiceBetCounter, NftBuyContractAddress, soloCounterAddress, timeExtenderAddress, tokenAddress } from "../config/config";
import { tokenAbi } from "../config/tokenAbi";
import { ethers } from "ethers";
import swal from "sweetalert";
import NFTAbi from "../common/NFTAbi.json";
import { useEffect, useMemo, useState } from "react";
import { betCounterAbi } from "../config/betCounterAbi";
import { soloCounterAbi } from "../config/soloCounterAbi";
import { multiCounterAbi } from "../config/multiCounterAbi";
import Countdown from "react-countdown";
import moment from "moment";
import { erc721Abi } from "../config/erc721Abi";
import { timeExtenderAbi } from "../config/timeExtenderAbi";

const ViewNFT = ({ showNFTView, setShowNFTView }) => {
    const web3AuthProvider = useSelector((state) => state.reducer.web3AuthProvider);
    const walletAddress = useSelector((state) => state.reducer.walletAddress);
    const [NFTContracts, setNFTContracts] = useState(mintNftsList);

    useEffect(() => {
        if (!walletAddress?.accounts && walletAddress && web3AuthProvider) {
            getBalanceOfNFT();
        }

    }, [walletAddress, web3AuthProvider])


    async function getBalanceOfNFT() {
        const web3 = new Web3(web3AuthProvider);
        const NFTContractsTemp = [];
        for await (const NFTContract of NFTContracts) {
            const mintContract = new web3.eth.Contract(NFTAbi, NFTContract.contract);
            const balanceOf = await mintContract.methods.balanceOf(walletAddress).call();
            const betCounterData = {}
            if (NFTContract.contractType === "GROUP" || NFTContract.contractType === "EXTENDED_SOLO" || NFTContract.contractType === "BET_FOLIO_SOLO" || NFTContract.contractType === "BET_FOLIO_MULTI") {
                // Group NFT
                let counterContract = new web3.eth.Contract(betCounterAbi, betCounterAddress);
                if (NFTContract.contractType === "BET_FOLIO_SOLO" || NFTContract.contractType === "EXTENDED_SOLO") {
                    // BetFolio Solo Prediction Pass
                    counterContract = new web3.eth.Contract(soloCounterAbi, soloCounterAddress);
                } else if (NFTContract.contractType === "BET_FOLIO_MULTI") {
                    //BetFolio Multichoice Predictions Pass
                    counterContract = new web3.eth.Contract(multiCounterAbi, multiChoiceBetCounter);
                }
                // const soloExtendedNftContract = getWeb3Contract(erc721Abi, soloExtendedNft);
                const getBetCount = await counterContract.methods.getBetCount(walletAddress).call();
                const canBetOnRiskFree = await counterContract.methods.canBetOnRiskFree(walletAddress).call();
                if (canBetOnRiskFree) {
                    const userBets = await counterContract.methods.userBets(walletAddress).call();
                    betCounterData.userBets = userBets;
                }
                betCounterData.getBetCount = Number(getBetCount);
                betCounterData.canBetOnRiskFree = canBetOnRiskFree;
            }

            if (NFTContract.bets === 1 && (NFTContract.contractType === "SOLO" || NFTContract.contractType === "MULTI")) {
                const counterContract = new web3.eth.Contract(timeExtenderAbi, timeExtenderAddress);
                if (NFTContract.contractType === "MULTI") {
                    const remainingMultiBets = await counterContract.methods.remainingMultiBets(walletAddress).call();
                    betCounterData.remainingMultiBets = Number(remainingMultiBets);
                } else if (NFTContract.contractType === "SOLO") {
                    const remainingSoloBets = await counterContract.methods.remainingSoloBets(walletAddress).call();
                    betCounterData.remainingSoloBets = Number(remainingSoloBets);
                }
            }
            NFTContractsTemp.push({
                ...NFTContract,
                balanceOfNFT: Number(balanceOf),
                ...betCounterData
            })

        }
        setNFTContracts(NFTContractsTemp);
    }

    const soloExtensionCount = useMemo(() => {
        const totalBalance = NFTContracts.reduce((accumulator, item) => {
            if (item.contractType == "SOLO") {
                return accumulator + (Number(item.bets) * (item.balanceOfNFT));
            } else {
                return accumulator;
            }
        }, 0);
        return totalBalance;
    }, [NFTContracts])


    const multiChoiceExtensionCount = useMemo(() => {
        const totalBalance = NFTContracts.reduce((accumulator, item) => {
            if (item.contractType == "MULTI") {
                return accumulator + (Number(item.bets) * (item.balanceOfNFT));
            } else {
                return accumulator;
            }
        }, 0);
        return totalBalance;
    }, [NFTContracts])

    let renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Closed</span>;
        } else {
            return (
                <span>
                    {days + "d"} {hours + "h"}:{minutes + "m"}:{seconds + "s"}
                </span>
            );
        }
    };

    function showExpiryAndStatus(item) {

        if (item.contractType === "GROUP" || item.contractType === "BET_FOLIO_MULTI") {
            const isValid =item?.canBetOnRiskFree;
            return (
                <>
                    {/* <p>Status: <span className={isValid > 0 ? `valid` : `not_valid`}>{`${item?.getBetCount}/${item?.balanceOfNFT}`}<span className="Valid-text ms-1"><i className="fa fa-ban me-1" aria-hidden="true"></i>{isValid ? `Valid` : `Not Valid`}</span></span> </p> */}
                    <p>Status: <span className={isValid > 0 ? `valid` : `not_valid`}>{`${item?.getBetCount ? item?.getBetCount :0}`}<span className="Valid-text ms-1"><i className="fa fa-ban me-1" aria-hidden="true"></i>{isValid ? `Valid` : `Not Valid`}</span></span> </p>

                    <p>Expiry countdown: <span>
                        {item?.userBets?.riskFreeExpiryTime ? <Countdown
                            date={moment(Number(item?.userBets?.riskFreeExpiryTime) * 1000).format("YYYY-MM-DD HH:mm:ss")}
                            renderer={renderer}
                        /> : ""}
                    </span> </p>
                </>
            )
        }
        if (item.contractType === "EXTENDED_SOLO") {
            const isValid = Number(item?.userBets?.extendedRemaingChance) > 0 && (parseInt(item?.userBets?.extendedExpiryTime) * 1000) > (new Date().getTime());

            return (
                <>
                    <p>Status: <span className={isValid > 0 ? `valid` : `not_valid`}>{`${Number(item?.userBets?.extendedRemaingChance) ? Number(item?.userBets?.extendedRemaingChance) : 0} `}<span className="Valid-text ms-1"><i className="fa fa-ban me-1" aria-hidden="true"></i>{isValid ? `Valid` : `Not Valid`}</span></span> </p>
                    <p>Expiry countdown: <span>
                        {item?.userBets?.extendedExpiryTime ? <Countdown
                            date={moment(Number(item?.userBets?.extendedExpiryTime) * 1000).format("YYYY-MM-DD HH:mm:ss")}
                            renderer={renderer}
                        /> : ""}
                    </span> </p>
                </>
            )
        }

        if (item.contractType === "BET_FOLIO_SOLO") {

            const isValid = Number(item?.userBets?.soloRemaingChance) > 0 && (parseInt(item?.userBets?.riskFreeExpiryTime) * 1000) > (new Date().getTime());
            return (
                <>
                    <p>Status: <span className={isValid > 0 ? `valid` : `not_valid`}>{`${Number(item?.userBets?.soloRemaingChance) ? Number(item?.userBets?.soloRemaingChance) : 0} `}<span className="Valid-text ms-1"><i className="fa fa-ban me-1" aria-hidden="true"></i>{isValid ? `Valid` : `Not Valid`}</span></span> </p>
                    <p>Expiry countdown: <span>
                        {item?.userBets?.riskFreeExpiryTime ? <Countdown
                            date={moment(Number(item?.userBets?.riskFreeExpiryTime) * 1000).format("YYYY-MM-DD HH:mm:ss")}
                            renderer={renderer}
                        /> : ""}
                    </span> </p>
                </>
            )
        }
    }

    function showStatus(remaining = 0, extensionCount) {
        return (
            <p>Status: <span className={remaining > 0 ? `valid` : `not_valid`}>{`${remaining ? remaining :0}`}<span className="Valid-text ms-1"><i className="fa fa-ban me-1" aria-hidden="true"></i>{remaining > 0 ? `Valid` : `Not Valid`}</span></span> </p>)

            // <p>Status: <span className={remaining > 0 ? `valid` : `not_valid`}>{`${remaining}/${extensionCount}`}<span className="Valid-text ms-1"><i className="fa fa-ban me-1" aria-hidden="true"></i>{remaining > 0 ? `Valid` : `Not Valid`}</span></span> </p>)
    }


    return (
        <>
            <Modal show={showNFTView} onHide={() => setShowNFTView(false)} centered className="view-nft-modal folio-popu">
                <Modal.Header closeButton>
                    <Modal.Title>View My NFTs</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0 mt-2">
                    <div className="view-nft-body">
                        <Row>
                            {NFTContracts.map(item => {
                                console.log("item", item);

                                if (item.contractType !== "SOLO" && item.contractType !== "MULTI") {
                                    return (<Col md={4}>
                                        <div className="under-view-nft">
                                            <p>NFT Name: <span>{item.mintName}</span> </p>
                                            <p>Total count: <span>{`${item?.balanceOfNFT}`}</span> </p>
                                            {showExpiryAndStatus(item)}

                                        </div>
                                    </Col>)
                                }
                            })}



                            <Col md={4}>
                                <div className="under-view-nft">
                                    <p className="border-0">NFT Name: <span>Solo Time Extension NFT</span> </p>
                                    <div className="subnodes">
                                        {NFTContracts.map(item => {
                                            if (item.contractType == "SOLO") {
                                                return (<p>{`${item.bets == 1 ? `${item.bets} bet ` : `${item.bets} bets `}`}<span className="highlisgtd-text"> {item?.balanceOfNFT}</span></p>)
                                            }
                                        })}
                                    </div>
                                    <p>Total count: <span>{soloExtensionCount}</span> </p>
                                    {showStatus(NFTContracts.find((item) => item.contractType == "SOLO" && item.bets === 1)?.remainingSoloBets, soloExtensionCount)}
                                </div>
                            </Col>

                            <Col md={4}>
                                <div className="under-view-nft">
                                    <p className="border-0">NFT Name: <span>Multi-choice Time Extension NFT</span> </p>
                                    <div className="subnodes">
                                        {NFTContracts.map(item => {
                                            if (item.contractType == "MULTI") {
                                                return (<p>{`${item.bets == 1 ? `${item.bets} bet ` : `${item.bets} bets `}`}<span className="highlisgtd-text">{item?.balanceOfNFT}</span></p>)
                                            }
                                        })}
                                    </div>
                                    <p>Total count: <span>{multiChoiceExtensionCount}</span> </p>
                                    {showStatus(NFTContracts.find((item) => item.contractType == "MULTI" && item.bets === 1)?.remainingMultiBets, soloExtensionCount)}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
};

export default ViewNFT;
